import React from "react"
import AceEditor from "react-ace"
import "brace/mode/javascript"
import "brace/mode/html"
import "brace/mode/jsx"
import "brace/theme/cobalt"

import "./CodeEditor.scss"

const CodeEditor = (props) => {
  const {
    className = "",
    onBlur,
    mode = "javascript",
    height = 240,
    id = "",
    value = "",
    defaultValue = "",
    onChange = () => {},
    readOnly = false,
    showGutter = true,
    showPlaceholder = false,
    debounceChangePeriod
  } = props

  const renderPlaceholder = () => {
    if (showPlaceholder) {
      return (
        `return new Promise((resolve, reject) => {
  //...
})`
      )
    }
    return null
  }

  return (
    <AceEditor
      className={className}
      style={props.style || {}}
      mode={mode}
      showGutter={showGutter}
      theme="cobalt"
      wrapEnabled
      tabSize={2}
      editorProps={{ $blockScrolling: true }}
      height={`${height}px`}
      width="auto"
      name={id}
      value={value}
      defaultValue={defaultValue}
      readOnly={readOnly}
      onChange={onChange}
      onBlur={onBlur}
      debounceChangePeriod={debounceChangePeriod}
      placeholder={renderPlaceholder()}
    />
  )
}

export default CodeEditor
